import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { VscDebugBreakpointData } from 'react-icons/vsc'
import imgDefault from '../../assets/image/placeholder.png'




function BlockFour({ title, text, listAbout, listServices, image, sloganPrincipal }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <section className="w-4/5 mx-auto md:py-32 py-10">
                <div className="md:flex md:flex-row flex flex-col-reverse md:p-0 px-2 content-reverse">
                    <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h1 className='pb-10 capitalize'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                : 'we have many years of experience'
                                        }
                                    </h1>
                                :
                                <h2 className="separator-about capitalize">
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className="pb-4">
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <VscDebugBreakpointData />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }

                        <ButtonContent btnStyle='three' />

                        <div className="w-full">
                            <span className="separator-about"></span>
                        </div>
                    </div>
                    <div className="md:w-[50%] h-auto relative flex justify-items-center items-center">
                        <div className="w-[80%] md:w-[70%] h-[300px] md:h-full bg2 rounded-3xl"></div>
                        <div className=" md:w-[80%] h-[100%] absolute top-[10%] left-[10%]">
                            <img
                                src={image ? image : imgDefault}
                                alt='no found'
                                loading='lazy'
                                className='w-full h-full object-cover rounded-3xl'
                                data-aos="fade-up"
                                data-aos-duration="3000" />
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default BlockFour;
